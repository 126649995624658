<template>
  <VContainer class="">
    <VCard class="mb-4" :loading="refreshing">
      <VCardTitle>
        Refresh languages
      </VCardTitle>
      <VCardSubtitle>
        In case there's a change in number of locales supported (like adding or
        removing some locales), click the button below
      </VCardSubtitle>
      <VCardActions class="d-flex flex-grow-1">
        <VBtn
          color="primary"
          :loading="refreshing || loading"
          @click="refreshLanguages"
        >
          <VIcon small class="mr-3">fa-sync-alt</VIcon>
          Refresh
        </VBtn>
      </VCardActions>
    </VCard>

    <VCard class="mb-4" :loading="syncing">
      <VCardTitle>
        Locales Synchronization
      </VCardTitle>
      <VCardSubtitle>
        To update locales' key-value pairs, select locales by click on buttons
        below then click "Sync" button
      </VCardSubtitle>
      <VCardActions class="d-flex flex-grow-1">
        <VBtnToggle multiple v-model="localesWillSync" dense>
          <VBtn
            v-for="locale in allLanguages"
            :key="locale"
            :value="locale"
          >
            {{ locale }}
            <i
              class="ml-1 flag"
              :class="`flag-${getFlagCodeFromLocale(locale)}`"
            ></i>
          </VBtn>
        </VBtnToggle>
        <VBtn
          class="ml-5"
          color="success"
          :loading="syncing"
          @click="syncLanguages"
          :disabled="!localesWillSync || !localesWillSync.length"
        >
          Sync
        </VBtn>
      </VCardActions>
      <VSnackbar :value="true" v-for="(item, idx) in syncMessages" :key="`syncMessages[${idx}]:${item}`" multi-line>
        {{ item }}
        <template v-slot:action="{ attrs }">
          <VBtn
            color="red"
            text
            v-bind="attrs"
            @click="removeSyncMessage(idx)"
          >
            Close
          </VBtn>
        </template>
      </VSnackbar>
    </VCard>

    <VCard>
      <VCardTitle>
        <span class="pt-3">Internationalization</span>
        <VSpacer />
        <VTextField
          class="mt-0 pl-6"
          v-model="query"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          placeholder="by key, value,.."
          prepend-icon="fa-search"
          :loading="loading"
        />
        <VAutocomplete
          class="mt-0 pl-6"
          deletable-chips
          multiple
          small-chips
          label="Show languages"
          :items="allLanguages"
          v-model="showLanguages"
          hide-details
          @input="fetchMap"
        />
      </VCardTitle>
      <VDataTable
        :headers="tableHeaders"
        :items="languageItems"
        :loading="loading"
        :search="query"
        :items-per-page="15"
        multi-sort
      >
        <!-- <template v-slot:[`header.${hi.value}`] v-for="hi in tableHeaders">
          <div class="d-inline-flex" :key="`header.${hi.value}`">{{ hi.text }} bruh</div>
        </template> -->
        <template v-slot:[`item.Method`]="{ item }">
          <strong :class="getMethodColor(item.Method)">{{
            item.Method
          }}</strong>
        </template>
      </VDataTable>
    </VCard>
  </VContainer>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import Loading from "@/components/Loading";
import errorHandle from "@/mixins/errorHandle";

// function formatDate(date) {
//   let newDate = date.split(" ");
//   let d = new Date(newDate[0]);
//   const options = {
//     year: "numeric",
//     month: "short",
//     day: "numeric",
//     hour: "numeric",
//     minute: "numeric",
//     second: "numeric",
//     hour12: false,
//   };
//   const dateFormat = new Intl.DateTimeFormat("en-US", options).format(d);
//   return dateFormat;
// }

export default {
  name: "LanguagesIndex",
  mixins: [errorHandle()],
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      syncing: false,
      refreshing: false,
      query: "",
      showLanguages: ["en"],
      // localeModels: [],
      localesWillSync: [],
      syncMessages: [],
    };
  },
  computed: {
    ...mapGetters("i18n", {
      allLanguages: "allLocales",
      languageSource: "languageSource"
    }),
    ...mapState("i18n", {
      localeMap: state => state.language.map
    }),

    allItems() {
      if (!this.allLanguages.length) {
        return {};
      }
      const output = {};
      this.allLanguages.forEach(lang => {
        let list = [];
        const map = this.localeMap[lang];
        if (map) {
          list = Object.keys(map).map(k => ({
            key: k,
            value: map[k]
          }));
        }
        output[lang] = list;
      });
      return output;
    },

    tableHeaders() {
      const output = [
        {
          text: "#",
          sortable: true,
          value: "no",
          width: 50
        },
        {
          text: "Key",
          sortable: true,
          value: "key"
        },
        ...this.showLanguages.map(lang => ({
          text: lang.toUpperCase(),
          sortable: false,
          value: lang
        }))
      ];

      return output;
    },
    languageItems() {
      if (!this.allItems[this.showLanguages[0]]) {
        return;
      }
      return this.allItems[this.showLanguages[0]].map((el, index) => {
        const output = {
          no: index,
          key: el.key
        };
        this.showLanguages.forEach(lang => {
          const map = this.allItems[lang];
          if (!map) {
            return;
          }
          const found = map.find(x => x.key == el.key);
          if (found) {
            output[lang] = found.value;
          }
        });

        return output;
      });
    }
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenSuccess: "OpenSuccess",
      CloseSuccess: "CloseSuccess",
      OpenError: "OpenError",
      CloseError: "CloseError"
    }),
    ...mapActions("i18n", {
      fetchLanguages: "fetchLanguages",
      fetchLanguageMap: "fetchLanguageMap",
      refreshLocales: "refreshLocales",
      publishLocales: "publishLanguages"
    }),

    getFlagCodeFromLocale(value) {
      switch (value) {
        case "en": {
          return "GB";
        }
        default: {
          return value.toUpperCase()
        }
      }
    },

    formatDate(date) {
      if (!date) {
        return;
      }
      let newDate = date.split(" ")[0] + " and " + date.split(" ")[3];

      return newDate;
    },

    init(force = false) {
      this.fetchLanguages(force)
        .then(() => {
          // this.localeModels = this.allLanguages.map(x => ({
          //   value: x,
          //   loading: false
          // }));
          return this.fetchMap(force);
        })
        .then(() => {
          this.CloseLoading();
        })
        .catch(err => {
          this.CloseLoading();
          console.error(err);
          this.OpenError({ message: this.getErrorMessage(err) });
        });
    },

    fetchMap(force = false) {
      const promises = this.showLanguages.map(lang =>
        this.fetchLanguageMap({ locale: lang, force })
      );
      this.loading = true;
      return Promise.all(promises).then(() => {
        this.$forceCompute("allItems");
        this.$forceCompute("languageItems");
        this.loading = false;
      });
    },

    syncLanguages() {
      if (!this.localesWillSync || !this.localesWillSync.length) {
        return;
      }
      this.syncMessages = [];
      this.syncing = true;
      this.publishLocales(this.localesWillSync)
        .then((res) => {
          this.fetchMap(true);
          if (res.messages && res.messages.length) {
            this.syncMessages.push(...res.messages);
          }
          this.localesWillSync = [];
        })
        .finally(() => {
          this.syncing = false;
        });
    },
    refreshLanguages() {
      this.refreshing = true;
      this.refreshLocales()
        .then(() => {
          this.init(true);
          this.allLanguages;
          this.showLanguages = this.showLanguages.filter(x => this.allLanguages.includes(x));
          this.localesWillSync = this.localesWillSync.filter(x => this.allLanguages.includes(x));
          this.$forceCompute("tableHeaders");
          // .forEach(x => {
          //   if (!this.allLanguages.includes(x)) {
          //     this.showLanguages.splice()
          //   }
          // })
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    removeSyncMessage(index) {
      if (index >= 0 && index < this.syncLanguages.length) {
        this.syncMessages.splice(index, 1);
      }
    }
  },
  created() {
    this.OpenLoading();
    this.init();
  },
  watch: {}
};
</script>

<style lang="scss" scoped></style>
